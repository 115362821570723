<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-4 lg:pb-12">
    <div
      class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
    >
      <p
        class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:my-8"
      >
        How it works
      </p>
    </div>
    <div class="mt-12">
      <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        <div class="pt-2">
          <div class="flow-root px-6 pb-8">
            <div class="mt-0 text-center">
              <img
                src="/images/see_vacancies.png"
                alt="See the vacancies"
                class="w-32 h-32 mx-auto"
              />
              <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">
                See the vacancies
              </h3>
              <p class="mt-5 text-lg px-12 text-gray-500">
                See the vacancies available
                <router-link class="text-blue-600" to="/jobs/all"
                  >here</router-link
                >
                and search for the perfect match to your colleague
              </p>
            </div>
          </div>
        </div>

        <div class="pt-2">
          <div class="flow-root px-6 pb-8">
            <div class="mt-0 text-center">
              <img
                src="/images/fill_form.png"
                alt="Fill the form"
                class="w-32 h-32 mx-auto"
              />
              <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">
                Fill the form
              </h3>
              <p class="mt-5 text-lg px-12 text-gray-500">
                Fill this
                <a href="#/rewards" @click="showPopupReferAFriend" class="text-blue-600"
                  >form</a
                >
                with your and your colleagues info
              </p>
            </div>
          </div>
        </div>

        <div class="pt-2">
          <div class="flow-root px-6 pb-8">
            <div class="mt-0 text-center">
              <img
                src="/images/get_in_touch.png"
                alt="Get in touch"
                class="w-32 h-32 mx-auto"
              />
              <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">
                Get in touch
              </h3>
              <p class="mt-5 text-lg px-12 text-gray-500">
                We'll get in touch with you to give you your reward
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pop-up-refer :popupDisplayed.sync="popupDisplayed"></pop-up-refer>
    <pop-up-thanks
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-thanks>
  </div>
</template>

<script>
import PopUpRefer from "./PopUpRefer.vue";
import PopUpThanks from "./PopUpThanks.vue";

export default {
  name: "HowItWorks",
  components: {
    PopUpRefer,
    PopUpThanks,
  },
  data: function() {
    return {
      popupDisplayed: "None",
    };
  },
  methods: {
    showPopupReferAFriend() {
      this.popupDisplayed = "Refer";
    },
  },
};
</script>

<style lang="scss" scoped></style>
