<template>
  <div class="bg-white">
    <main>
      <refer-and-earn></refer-and-earn>
      <how-it-works></how-it-works>
      <testimonials></testimonials>
      <frequently-asked-questions></frequently-asked-questions>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import ReferAndEarn from '@/components/rewards/ReferAndEarn.vue';
import HowItWorks from '../components/rewards/HowItWorks.vue';
import Testimonials from '../components/rewards/Testimonials.vue';
import FrequentlyAskedQuestions from '../components/rewards/FrequentlyAskedQuestions.vue';

export default {
  name: 'Rewards',
  components: {
    ReferAndEarn,
    HowItWorks,
    Testimonials,
    FrequentlyAskedQuestions,
  }
}
</script>
