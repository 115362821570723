<template>
  <!-- Hero section -->
  <div class="relative">
    <div class="max-w-7xl mx-auto lg:px-0">
      <div class="relative shadow-xl sm:overflow-hidden">
        <div class="absolute inset-0">
          <img
            class="h-full w-full object-cover"
            src="/images/rewards_banner.jpg"
            alt="Refer and earn"
          />
          <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        </div>
        <div class="lg:grid lg:grid-cols-12">
          <div
            class="relative sm:px-6 sm:py-24 lg:pt-96 lg:pb-32 lg:pl-16 lg:col-start-1 lg:row-start-1 lg:col-span-12"
          >
            <h1
              class="text-left text-3xl font-bold tracking-tight sm:text-5xl lg:text-4xl text-white w-3/4"
            >
              <span class="block tracking-wide">Refer a colleague and earn</span>
            </h1>
            <h1
              class="text-left text-3xl font-bold tracking-tight sm:text-5xl lg:text-4xl text-white w-3/4"
            >
              <span class="block tracking-wide">up to $2,000mxn</span>
            </h1>
            <div
              class="mt-4 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-left"
            >
              <button
                @click="showPopupReferAFriend"
                class="flex items-center justify-center px-10 py-2 border border-transparent text-lg font-light shadow-sm text-white bg-blue-600 bg-opacity-100 hover:bg-opacity-80 sm:px-8 tracking-wider"
              >
                Refer and earn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pop-up-refer
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-refer>
    <pop-up-thanks
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-thanks>
  </div>
</template>

<script>
import PopUpRefer from "./PopUpRefer.vue";
import PopUpThanks from "./PopUpThanks.vue";
export default {
  name: "ReferAndEarn",
  components: {
    PopUpRefer,
    PopUpThanks,
  },
  data: function() {
    return {
      popupDisplayed: 'None',
    }
  },
  methods: {
    showPopupReferAFriend() {
      this.popupDisplayed = 'Refer';
    },
  },
};
</script>

<style scoped></style>
