<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-4">
    <div
      class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
    >
      <p
        class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:my-8"
      >
        Still curious?
      </p>
    </div>
    <div class="flex max-w-7xl mx-auto sm:px-6 lg:px-0 my-4" v-for="question in questions" :key="question.id">
      <div
        class="relative rounded-lg border border-gray-300 hover:bg-black hover:text-white cursor-pointer p-4 shadow-lg w-11/12"
        @click="toggleAnswer(question)"
      >
        <h1 class="flex font text-base">
          <span>{{ question.text }}</span>
          <span class="absolute right-4">
            <icon name="chevron-down" class="w-6 h-6" />
          </span>
        </h1>
        <collapse-transition group>
          <div
            class="ml-4"
            v-if="question.visible"
            :key="`show-${question.id}`"
          >
            <hr class="border-gray-300 mr-8 my-2">
            <h2 class="font-normal text-base" v-html="question.answer"></h2>
          </div>
        </collapse-transition>
      </div>
    </div>
    <div
      class="relative bg-white lg:col-start-7 lg:row-start-1 lg:my-8 lg:col-span-5 lg:grid lg:grid-cols-12 lg:items-center"
    >
      <div
        class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-10 text-left"
      >

        <button
          class="block w-full py-2 px-16 font-light text-center bg-blue-600 bg-opacity-100 hover:bg-opacity-80 border border-transparent shadow-md text-base text-white sm:inline-block sm:w-auto tracking-wider"
          @click="showPopupReferAFriend"
          >Refer a colleague</button
        >
      </div>
    </div>
    <pop-up-refer :popupDisplayed.sync="popupDisplayed"></pop-up-refer>
    <pop-up-thanks
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-thanks>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import PopUpRefer from "./PopUpRefer.vue";
import PopUpThanks from "./PopUpThanks.vue";

export default {
  name: "FrequentlyAskedQuestions",
  components: {
    CollapseTransition,
    PopUpRefer,
    PopUpThanks,
  },
  data: function() {
    return {
      popupDisplayed: "None",
      questions: [
        {
          id: 1,
          visible: false,
          text: "Who can I recommend?",
          answer: "You can recommend all your coworkers, family or friends, we are sure that you can connect them with a wonderful job at ScreenIT.",
        },
        {
          id: 2,
          visible: false,
          text: "Is there a limit on the number of recommendations?",
          answer: "No, we will be happy to receive the recommendations you want to make and we are committed to find the best job for them.",
        },
        {
          id: 3,
          visible: false,
          text: "What information do you request from my referral?",
          answer: "It is very simple, you only need Name, Email, Telephone and the main technology of your colleague expertise.",
        },
        {
          id: 4,
          visible: false,
          text: "How do I know that my referral has already been hired?",
          answer: "Once you have recommended someone, you will be asked for your email and telephone number. We will give you the good news though them.",
        },
        {
          id: 5,
          visible: false,
          text: "How is payment made?",
          answer: "Once your colleague is hired, within the next 5 days, you will be asked for general information; full name, email, RFC, CURP, INE and the bank account to where you want us to make the deposit.",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(question) {
      this.closeAllQuestions(question.id);
      this.questions.find(
        (q) => q.id === question.id
      ).visible = !question.visible;
    },
    closeAllQuestions(questionId) {
      for (const question of this.questions) {
        if (questionId === question.id) {
          continue;
        }
        question.visible = false;
      }
    },
    showPopupReferAFriend() {
      this.popupDisplayed = "Refer";
    },
  },
}
</script>

<style lang="scss" scoped>
</style>