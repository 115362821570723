<template>
  <section class="bg-black">
    <p
      class="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl lg:pt-8 lg:px-8 text-left"
    >
      What people are saying
    </p>
    <div class="max-w-7xl mx-auto md:grid md:grid-cols-3 md:px-6 lg:px-8">
      <div
        class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:px-8"
      >
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative text-xl font-light">
              From the beginning I felt accompanied and well informed about all
              the processes that should be follow.
              <br /><br />
              Communication never failed and all my doubts were solved during
              the process.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="flex-shrink-0 inline-flex rounded-full"></div>
              <div class="ml-4">
                <div class="text-base font-medium text-white">- Karina</div>
                <div class="text-base font-medium text-gray-200"></div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div class="py-12 px-4 sm:px-6 md:py-16 md:pr-0 md:pl-10 lg:px-8">
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative text-xl font-light">
              From the beginning I felt accompanied and well informed about all
              the processes that should be follow.
              <br /><br />
              Communication never failed and all my doubts were solved during
              the process.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="flex-shrink-0 inline-flex rounded-full"></div>
              <div class="ml-4">
                <div class="text-base font-medium text-white">
                  - Rubén
                </div>
                <div class="text-base font-medium text-gray-200"></div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div
        class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:px-8"
      >
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative text-xl font-light">
              From the beginning I felt accompanied and well informed about all
              the processes that should be follow.
              <br /><br />
              Communication never failed and all my doubts were solved during
              the process.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="flex-shrink-0 inline-flex rounded-full"></div>
              <div class="ml-4">
                <div class="text-base font-medium text-white">- Carla</div>
                <div class="text-base font-medium text-gray-200"></div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonials",
  components: {},
};
</script>

<style scoped></style>
